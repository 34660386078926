html, body {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-weight: 300;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
        flex: 1;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiContainer-root {
    padding-top: 20px;
    padding-bottom: 20px;
}

.img-responsive {
    height: auto;
    width: 100%;
}
